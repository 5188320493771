import Axios from 'axios';

export const refreshToken = Token =>
  new Promise((resolve, reject) => {
    // let now = new Date();
    // if (parseInt(now.getTime() / 1000) > Token.expires_in) {
    Axios.post(Constants.mainURL + '/user/login/' + Token.refresh_token)
      .then(response => {
        resolve(response.data.data[0]);
      })
      .catch(error => {
        reject(error);
      });
    // } else resolve(Token);
  });

export const hasPermission = (permissions, instance) => {
  let array = permissions.match(/.{1,4}/g);
  return array.includes(instance);
};

export const getErrorText = error =>
  error?.response?.data?.message ||
  error?.response?.data?.msg ||
  error?.response?.data ||
  error?.response ||
  error;

// export const refreshToken = async (Token) => {
// 	console.log("refresh token", Token);
// 	let now = new Date();
// 	try {
// 		let response = await Axios.post(
// 			Constants.mainURL + "/user/login/" + Token.refresh_token
// 		);
// 		console.warn("inside RefreshToken: ", response);
// 		return response.data.data[0];
// 	} catch (error) {
// 		console.log("refresh token EEEERRRORR", error, error.response);
// 		return error;
// 	}
// };

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function persianNumToEnglish(value) {
  if (!value?.length) return '';

  const persian = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return (value + '').replace(/[۰-۹]/g, chr => {
    const idx = persian.indexOf(chr);
    return idx >= 0 ? idx : chr;
  });
}

export const Constants = {
  mainURL: 'https://api.moholand.com/api/v2',
  // remoteUploadURL: '/media/upload/remote',
  // mainURL: "https://api.moholand.ir/api/v1",
  // mainURL: "https://projectapi.kicket.ir/api/v1",
  // mainURL: 'http://localhost:8000/api/v2',
  supportVideoTypes: 'video/mp4, video/webm',
  roles: [
    { name: 'مربی', type: 'coach' },
    { name: 'بازیکن', type: 'player' },
    { name: 'مدیر', type: 'manager' },
  ],
  ordersStatus: [
    'معلق',
    'در حال آماده سازی',
    'آماده به ارسال - انجام',
    'ارسال شده شخصی',
    'خدمات انجام شده',
    'ارسال شده',
    'غیر قابل قبول',
    'معطله',
    'غیر قابل توزیع',
    'تایید شده',
    'پیش برگشتی',
    'برگشتی نهایی',
    'انصرافی',
    'خطا در وضعیت گیت وی',
    'انجام شده',
  ],
  paymentsStatus: ['آنلاین', 'پرداخت درب منزل', 'پرداخت اعتباری'],
  discountTypes: [
    { id: 1, value: 'کد تخفیف با مقدار' },
    { id: 2, value: 'تخفیف زمان دار' },
  ],
  filterNumber: t => {
    if (!t || !t.value) return '';
    if (t.value.includes(',')) t.value = t.value.split(',').join('');
    if (!/^\d+$/.test(t.value)) {
      return t.value;
    }
    return '' + Intl.NumberFormat().format(+t.value);
  },
  Colors: {
    main: '#002AFA',
    success: '#00D432',
    disabled: '#AFAFAF',
    danger: '#BB3341',
    border: '#C2C2C2',
    dark: '#555555',
  },
  coursesCategories: {
    1: 'مقدماتی',
    2: 'پیشرفته',
    3: 'حرفه ای',
  },
  rules: {
    required: value => !!value || 'وارد کردن این مورد الزامی است',
    required_price: value =>
      value.length > 0 || 'وارد کردن این مورد الزامی است',
    array_required: value => value.length || 'وارد کردن این مورد الزامی است',
    array_max: count => v =>
      v == null || v.length <= count || `حداکثر ${count} انتخاب مجاز است`,
    min: v => v == null || v.length <= 0 || v.length >= 8 || 'حداقل 8 کاراکتر',
    national_code: v =>
      v == null || v.length == 10 || 'کد ملی وارد شده صحیح نیست',
    numeric: v =>
      v == null ||
      v.length <= 0 ||
      /(^[0-9|,]*$)/.test(v) ||
      'این فیلد باید یک عدد و بدون فاصله باشد',
    cellphone: v =>
      v == null ||
      v.length <= 0 ||
      (/(^09[0-9]*$)/.test(v) && v.length == 11) ||
      'شماره تلفن همراه صحیح نیست',
    phone: v =>
      v == null ||
      v.length <= 0 ||
      (/(^0[0-9]*$)/.test(v) && v?.length == 11) ||
      'شماره تلفن صحیح نیست',
    persian_string: v =>
      v == null ||
      v.length <= 0 ||
      /(^[آ-ی+\s]*$)/.test(v) ||
      'نام وارد شده باید فارسی باشد.',
    english_string: v =>
      v == null ||
      v.length <= 0 ||
      /(^[A-Z+a-z+\s]*$)/.test(v) ||
      'نام وارد شده باید انگلیسی باشد.',
    email: v =>
      v == null ||
      v.length <= 0 ||
      // /.+@.+/.test(v) ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      'ایمیل وارد شده صحیح نیست',
    withoutSpace: v =>
      v == null ||
      v.length <= 0 ||
      !(v.includes(' ') || v.includes('-') || v.includes('_')) ||
      'لطفا محتوا را بدون فاصله بنویسید.',
  },
};
