<template>
  <v-navigation-drawer
    v-model="show"
    right
    app
    temporary
    draggable
    style="z-index: 101"
  >
    <v-list-item class="orange lighten-5">
      <v-list-item-content>
        <v-list-item-title class="white--text text-center">
          <v-img
            contain
            class="white--text"
            max-height="100px"
            max-width="250px"
            :src="
              $store.state.settings.logo
                ? $store.state.settings.logo.url
                : require('../../assets/img/logo-persian.png')
            "
          >
          </v-img>
        </v-list-item-title>

        <v-card style="background: #FF6D00" class="pa-2 mt-8 mx-auto" rounded>
          <v-row align="end" class="fill-height">
            <v-col align-self="middle" class="pa-0" cols="12">
              <v-menu
                open-on-hover
                bottom
                offset-y
                v-if="$store.state.userData"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-center align-center">
                    <v-list-item
                      color="rgba(0, 0, 0, .4)"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            $store.state.userData.first_name +
                              " " +
                              $store.state.userData.last_name
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <small>اعتبار شما:</small>
                          <small v-if="$store.state.userData">
                            {{
                              Intl.NumberFormat().format(
                                $store.state.userData.balance
                              )
                            }}
                            تومان
                          </small>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-avatar
                      class="profile ml-2"
                      color="grey"
                      size="42"
                      rounded
                    >
                      <v-img
                        :src="
                          $store.state.userData.avatar_url ||
                            require('@/assets/img/man.jpg')
                        "
                      ></v-img>
                    </v-avatar>
                  </div>
                </template>
              </v-menu>
              <v-btn v-if="$store.state.userData == null" text dark to="/login">
                همین حالا وارد شوید
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-content>
    </v-list-item>

    <v-list dense nav>
      <template v-if="$store.state.userData">
        <h5 class="my-2">منو های پنل</h5>
        <v-list>
          <v-list-item link style="max-width: unset" :to="'/dashboard'">
            <v-list-item-title>پنل کاربری</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            style="max-width: unset"
            :to="'/sellers/' + $store.state.userData.id"
            v-if="
              $store.state.userData &&
                $store.state.userData.roleName == 'vendor'
            "
          >
            <v-list-item-title>فروشگاه من</v-list-item-title>
          </v-list-item>
          <v-list-item style="max-width: unset" link @click="exit">
            <v-list-item-title>خروج</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <v-divider class="my-2" />
      <h5 class="my-2">منو های سایت</h5>
      <div v-for="(item, i) in items" :key="'menu' + i" link>
        <v-list-group v-if="item.child.length">
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <template v-if="item.child.some((v) => v.child.length)">
            <v-list-group
              sub-group
              v-for="(child, j) in item.child"
              :key="'childdrawer' + j"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </template>
              <v-list-item
                class="grey lighten-3"
                v-for="(subchild, k) in child.child"
                :key="'childdrawer-subgroup' + k"
                :to="subchild.url ? '/' + subchild.url : ''"
              >
                <v-list-item-content>{{ subchild.title }}</v-list-item-content>
              </v-list-item>
            </v-list-group></template
          >
          <template v-else>
            <v-list-item
              v-for="(child, j) in item.child"
              :key="'childdrawer-' + j"
              class="grey lighten-4"
              :to="'/' + child.url"
            >
              <v-list-item-content>{{ child.title }}</v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          v-else
          :to="item.url ? '/' + item.url : ''"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      show: false,
      items: [],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        console.log(this.items);
      },
    },
  },
  methods: {
    toggle: function() {
      this.show = !this.show;
    },
    navigateTo: function(name) {
      this.$router.push({ name });
    },
    exit: function() {
      this.$store.commit("exit");
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
span {
  color: white;
}
</style>
